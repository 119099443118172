body {
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;

  .header {
    height: 80px;
    background-color: #fff;
    box-shadow: 0px 4px 4px 0px rgba(231, 231, 231, 0.25);
    position: sticky;
    top: 0px;
    z-index: 1000;
    display: flex;
    align-items: center;
    @media (max-width: 1023px) {
      display: none;
    }

    .navbar-items {
      display: flex;
      column-gap: 12px;
      align-items: center;

      .navbar-link {
        padding: 4px 12px;
        cursor: pointer;
        color: #192851;
        font-size: 16px;
        font-weight: 600;
        position: relative;
        &:hover {
          background-color: #eeeefb;
          border-radius: 4px;
          font-weight: 700;
          .dropdown {
            display: block;
          }
        }
        .dropdown {
          display: none;
          position: absolute;
          top: 100%;
          width: 240px;
          left: 0;
          background-color: #fff;
          list-style: none;
          margin: 0;
          min-width: 150px;
          border-radius: 4px;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
          .dropdown-item {
            padding: 4px 8px;
            color: white;
            display: flex;
            column-gap: 12px;
            cursor: pointer;
            align-items: center;
            &:hover {
              background-color: #fafaff;
            }
            .dropdown-item-label {
              color: #192851;
              font-family: Raleway;
              font-size: 14px;
              font-weight: 500;
            }
            img {
              padding: 6px;
              width: 32px;
            }
          }
        }
      }
    }

    .navbar-brand {
      width: 162px;
      object-fit: contain;
      cursor: pointer;
    }
  }

  .mobile-header {
    @media (max-width: 1023px) {
      display: flex;
    }
    height: 80px;
    position: sticky;
    top: 0px;
    z-index: 1000;
    display: none;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #fff;
    color: white;
    .logo {
      width: 200px;
      object-fit: contain;
      cursor: pointer;
    }
    .hamburger {
      font-size: 28px;
      cursor: pointer;
      color: black;
    }
    .dropdown-menu {
      position: absolute;
      top: 60px;
      right: 20px;
      background-color: white;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      overflow: hidden;
      z-index: 1000;
      display: flex;
      flex-direction: column;
      a {
        padding: 10px 20px;
        text-decoration: none;
        color: #000;
        border-bottom: 1px solid #ddd;
        &:last-child {
          border-bottom: none;
        }
        &:hover {
          background-color: rgb(36, 38, 41, 0.3);
        }
      }
    }
    .menu {
      display: none;
      background-color: #fff;
      position: absolute;
      top: 70px;
      left: 0;
      width: 100%;
      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        li {
          border-bottom: 1px dashed rgba(25, 40, 81, 0.1);
          padding: 12px 32px;
          .title {
            color: #454545;
            display: block;
            text-decoration: none;
            font-family: Raleway;
            font-size: 16px;
            font-weight: 700;
            &:hover {
              background-color: #fafaff;
            }
          }
          #submenu {
            display: none;
            background-color: #fff;
          }
        }
      }
    }
  }

  .footer {
    color: #f3f5fb;
    align-items: center;
    background-color: #192851;
    display: flex;
    flex-direction: column;
    padding-top: 64px;
    @media (max-width: 1023px) {
      padding: 32px 24px;
    }

    .logo {
      width: 150px;
      object-fit: contain;
      width: 180px;
    }

    .content-section {
      align-items: flex-start;
      align-self: stretch;
      display: flex;
      flex: 0 0 auto;
      flex-direction: column;
      gap: 16px;

      .content-category {
        color: #f3f5fb;
        font-weight: 700;
        font-family: "Raleway";
      }

      .footer-links {
        align-items: flex-start;
        align-self: stretch;
        display: flex;
        flex: 0 0 auto;
        flex-direction: column;
        gap: 8px;
      }

      .link {
        cursor: pointer;
        align-self: stretch;
        color: #f3f5fb;
        font-family: "Open Sans";
        text-wrap: nowrap;
      }
    }

    .copyright {
      width: 100%;
      .container {
        display: flex;
        flex-direction: column;
        row-gap: 2px;
        flex: 0 0 auto;
        color: #f3f5fb;
        padding: 24px 0px;
        width: 100%;
      }
    }
  }

  .cta-button {
    cursor: pointer;
    background-color: #6f58ff;
    color: #ffffff;
    font-family: "Open Sans";
    border-radius: 8px;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: 600;
    border: unset;
    line-height: 16px;
    &:hover {
      background-color: #5a48e0;
    }
  }

  .home-content {
    background-color: rgba(146, 144, 221, 0.2);
    display: flex;
    flex-direction: column;
    section {
      padding-top: 120px;
      @media (max-width: 1023px) {
        padding-top: 64px;
      }
    }
    .home-page-intro {
      @media (max-width: 1023px) {
        text-align: center;
      }
      .overview-title {
        color: #192851;
        font-size: 56px;
        font-weight: 500;
        line-height: 60px;
        margin-bottom: 20px;
        @media (max-width: 1023px) {
          font-size: 32px;
          line-height: 32px;
          text-align: center;
        }
      }
      .overview-description {
        color: #192851;
        font-size: 20px;
        margin-bottom: 24px;
        width: 80%;
        font-family: "Open Sans";
        @media (max-width: 1023px) {
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          width: 85%;
          margin-inline: auto;
        }
      }
      .certifications {
        margin-top: 56px;
        display: flex;
        column-gap: 44px;
        align-items: center;
        @media (max-width: 1023px) {
          justify-content: center;
          margin-top: 24px;
        }
        img {
          height: 64px;
          width: auto;
        }
      }
    }

    .products {
      margin-top: 145px;
      background: radial-gradient(
        227.74% 154.17% at 37.84% 7.09%,
        #8487fc 20.17%,
        #8487f9cc 91.72%
      );
      border-radius: 48px;
      padding: 64px 40px;
      @media (max-width: 1023px) {
        border-radius: unset;
        margin-inline: 0px;
        padding: 32px 16px;
      }
      .title {
        color: #fff;
        font-size: 40px;
        text-align: center;
        font-weight: 600;
        line-height: 48px;
        margin-bottom: 12px;
        width: 75%;
        margin: 0 auto;
        @media (max-width: 1023px) {
          font-size: 28px;
          width: 100%;
          margin-bottom: 16px;
        }
      }
      .description {
        color: #fff;
        font-size: 20px;
        text-align: center;
        width: 75%;
        margin-top: 8px;
        margin-inline: auto;
        margin-bottom: 48px;
        font-family: "Open Sans";
        @media (max-width: 1023px) {
          font-size: 16px;
        }
      }
      .homepage-tabs {
        position: sticky;
        top: 120px;
      }
    }

    .integrations {
      background-color: #fafaff;
      display: flex;
      flex-direction: column;
      row-gap: 48px;
      padding-bottom: 120px;
      background-image: url("./assets/background-animation.svg");
      background-repeat: no-repeat;
      background-position: bottom;
      .integration-title {
        color: #192851;
        font-size: 40px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 48px;
      }
      .integration-cards {
        display: flex;
        justify-content: center;
        column-gap: 48px;
        row-gap: 24px;
        flex-wrap: wrap;
        .card {
          padding: 24px;
          display: flex;
          flex-direction: column;
          row-gap: 24px;
          background-color: #8689fc;
          border-radius: 12px;
          border: unset;
          flex: 1;
          min-width: 368px;
          max-width: 368px;
          img {
            width: 28px;
            height: 28px;
          }
          .title {
            color: #fff;
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 8px;
          }
          .description {
            font-size: 16px;
            color: #fff;
            font-family: "Open Sans";
          }
        }
      }
    }

    .faq {
      background-color: #fafaff;
      padding-bottom: 120px;
      .title {
        color: #192851;
        font-size: 40px;
        font-weight: 600;
        text-align: center;
      }
      .queries {
        margin-top: 64px;
        display: flex;
        flex-direction: column;
        row-gap: 12px;
      }
    }

    .get-ready {
      background-color: #f4f3fd;
      padding: 40px 0px;
      .container {
        display: flex;
        row-gap: 24px;
        flex-direction: column;
        align-items: center;
        .title {
          color: #192851;
          font-size: 40px;
          font-weight: 600;
        }
      }
    }
  }

  .section-title {
    color: #192851;
    font-size: 40px;
    font-weight: 600;
  }
  .section-description {
    color: #192851;
    font-size: 20px;
    font-weight: 400;
  }
  .description-card {
    display: flex;
    row-gap: 32px;
    flex-direction: column;
    flex: 1;
    min-width: 300px;
    max-width: 330px;
    @media (max-width: 1023px) {
      row-gap: 24px;
      &.p-4 {
        padding: 8 !important;
      }
    }
    .description-icon {
      width: 36px;
      height: 36px;
      background-color: #8689fc;
      border-radius: 4px;
      img {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .description-content {
      display: flex;
      row-gap: 4px;
      font-family: "Open Sans";
      flex-direction: column;
      .title {
        color: #343c6a !important;
        font-size: 20px !important;
        font-weight: 600 !important;
        margin-bottom: 8px;
      }
      .description {
        color: #192851 !important;
      }
    }
  }

  .checkout-content {
    background-color: #f1f0fc;
    padding-top: 120px;
    @media (max-width: 1023px) {
      padding-top: 0px;
    }

    .section-block {
      margin-bottom: 145px;
      @media (max-width: 1023px) {
        padding: 95px 8px 0px;
        margin-bottom: 72px;
      }

      &.wave {
        position: relative;
        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 50%;
          background: url("./assets/wave-bckground.svg") no-repeat center center;
          background-size: cover;
          opacity: 0.15; /* Adjust the opacity here */
          z-index: 0;
        }
      }
      .wave > * {
        position: relative;
        z-index: 1;
      }

      section {
        margin-bottom: 120px;
        @media (max-width: 1023px) {
          margin-bottom: 64px;
        }
      }
      .overview-subtitle {
        color: #192851;
        font-size: 20px;
        line-height: 30px;
        text-transform: uppercase;
        margin-bottom: 16px;
      }

      .overview-title {
        color: #192851;
        font-size: 56px;
        font-weight: 500;
        line-height: 67px;
        margin-bottom: 20px;
      }
      .overview-description {
        color: #192851;
        font-size: 18px;
        margin-bottom: 24px;
      }

      .descriptions {
        display: flex;
        gap: 16px;
        flex-wrap: wrap;
        margin-top: 48px;
      }
    }

    .payment-methods {
      border-radius: 48px 48px 0px 0px;
      padding: 64px 0px;
      background: linear-gradient(126deg, #8487fc 65.71%, #f7f8ff 104.91%);
      &.bg-white-custom {
        background: unset;
        background-color: #fafaff;
        border-radius: unset;
        .title {
          color: #192851 !important;
        }
        .description {
          color: #192851 !important;
        }
      }
      &.no-bg {
        background: unset;
        background-color: #f1f0fc;
        border-radius: unset;
        .title {
          color: #192851 !important;
        }
        .description {
          color: #192851 !important;
        }
      }
      @media (max-width: 1023px) {
        border-radius: unset;
        padding: 32px 4px;
      }
      .title {
        text-align: center;
        color: #fafaff;
        font-size: 40px;
        font-weight: 500;
        @media (max-width: 1023px) {
          font-size: 28px;
        }
      }
      .description {
        color: #fafaff;
        text-align: center;
        font-family: "Open Sans";
        font-size: 20px;
        font-weight: 400;
        @media (max-width: 1023px) {
          font-size: 16px;
        }
      }
      .row {
        margin-top: 48px;
        @media (max-width: 1023px) {
          margin-top: 32px;
        }
      }
      .payment-cards {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        row-gap: 12px;
        margin-top: 48px;
        @media (max-width: 1023px) {
          margin-top: 24px;
        }
        .card {
          padding: 20px 24px;
          background-color: #ffffff;
          border-radius: 12px;
          flex: 1;
          min-width: 280px;
          max-width: 300px;
          border: unset;
          display: flex;
          flex-direction: column;
          row-gap: 40px;
          @media (max-width: 1023px) {
            min-width: 100%;
            row-gap: 24px;
          }
          .images-section {
            display: flex;
            column-gap: 4px;
            align-items: center;
            .image {
              border-radius: 4px;
              padding-inline: 6px;
              height: 36px;
              width: 36px;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                width: 100%;
              }
            }
          }

          .card-title {
            color: #192851;
            font-size: 24px;
            font-weight: 600;
          }
          .card-description {
            color: #192851;
            font-size: 16px;
            font-family: "Open Sans";
          }
        }
      }
      .image {
        text-align: center;
        img {
          width: 475px;
          &.small {
            width: unset;
            height: 350px;
          }
          @media (max-width: 1023px) {
            width: 300px;
          }
        }
      }
    }

    .automation {
      padding: 64px 0px;
      @media (max-width: 1023px) {
        padding: 32px 36px;
      }
      .container {
        display: flex;
        flex-direction: column;
        row-gap: 48px;
        .automation-title {
          color: #192851;
          text-align: center;
          font-size: 40px;
          font-weight: 600;
          @media (max-width: 1023px) {
            font-size: 32px;
            text-align: left;
          }
        }
      }
    }
  }

  .about-us {
    .header-content {
      background: linear-gradient(186deg, #8487fc 55.14%, #f7f8ff 177.69%);
      padding: 120px 0px;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url("./assets/wave-bckground.svg") no-repeat center center;
        background-size: cover;
        opacity: 0.2; /* Adjust the opacity here */
        z-index: 0;
      }
      @media (max-width: 1023px) {
        padding: 64px 36px 48px 36px !important;
      }
      .title {
        font-size: 56px;
        font-weight: 500;
        color: #fff;
        margin-bottom: 20px;
        line-height: 65px;
        @media (max-width: 1023px) {
          font-size: 28px;
          line-height: 32px;
        }
      }
      .description {
        font-size: 20px;
        color: #fff;
        width: 85%;
        line-height: 30px;
        font-family: "Open Sans";
        @media (max-width: 1023px) {
          font-size: 16px;
          width: 100%;
          line-height: 24px;
        }
      }
    }
    .header-content > * {
      position: relative;
      z-index: 1;
    }
    .header-team-content {
      background-color: #fff;
      padding: 86px 0px;
      @media (max-width: 1023px) {
        padding: 32px 36px;
      }
      .title {
        color: #192851;
        font-size: 40px;
        font-weight: 600;
        margin-bottom: 20px;
        @media (max-width: 1023px) {
          font-size: 28px;
        }
      }
      .description {
        color: #192851;
        font-size: 20px;
        font-family: "Open Sans";
        @media (max-width: 1023px) {
          font-size: 16px;
        }
      }
      .payments {
        display: flex;
        column-gap: 48px;
        align-items: center;
      }
    }
    .header-values-content {
      background-color: #f1f0fc;
      padding: 64px 0px;
      @media (max-width: 1023px) {
        padding: 32px 36px;
      }
      .values-title {
        color: #192851;
        font-size: 40px;
        font-weight: 600;
        margin-bottom: 20px;
        @media (max-width: 1023px) {
          font-size: 28px;
        }
      }
      .values-description {
        color: #192851;
        font-family: "Open Sans";
        font-size: 20px;
        width: 90%;
        margin-bottom: 32px;
        @media (max-width: 1023px) {
          font-size: 16px;
        }
      }
    }
  }

  .legal {
    .legal-content {
      padding-top: 120px;
      padding-bottom: 60px;
      background-color: rgba(146, 144, 221, 0.2);
      @media (max-width: 1023px) {
        padding-top: 64px;
      }
      .title {
        color: #192851;
        font-size: 56px;
        font-weight: 500;
        @media (max-width: 1023px) {
          font-size: 28px;
        }
      }
    }
    .content {
      background-color: #fafaff;
      color: #192851;
      padding: 75px 0px 60px;
      .title {
        color: #192851;
        font-size: 40px;
        font-weight: 600;
        margin-bottom: 36px;
      }
      .sub-title {
        color: #192851;
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 24px;
      }
      .data {
        padding-left: 1rem;
        font-family: "Open Sans";
        color: #192851;
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        display: flex;
        flex-direction: column;
        row-gap: 32px;
      }
    }
  }
  .login-content {
    background-color: #fafaff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 80px);
    flex-direction: column;
    .login-card {
      padding: 44px;
      background-color: #fff;
      border-radius: 12px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      border: solid 1px #f6f6f6;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
      width: 500px;
      .title {
        color: #192851;
        text-align: center;
        font-family: "Inter";
        font-size: 24px;
        font-weight: 500;
        line-height: 40px;
      }
      .sub-title {
        color: #192851;
        text-align: center;
        font-family: "Inter";
        font-size: 14px;
        font-weight: 400;
        line-height: 28px; /* 200% */
      }
      form {
        display: flex;
        flex-direction: column;
        row-gap: 24px;
        .form-group {
          label {
            color: #192851;
            font-family: "Inter";
            font-size: 18px;
            font-weight: 500;
            line-height: 40px;
          }
          .forgot-password {
            color: #6f58ff;
            font-family: Inter;
            font-size: 14px;
            font-weight: 500;
            line-height: 28px;
            text-decoration: none;
          }
          .input-field {
            padding: 12px 24px;
            border-radius: 12px;
            border: solid 1px #d1d1d1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            input {
              border: unset;
              padding: 0;
              &:focus {
                outline: none;
                border: unset;
                box-shadow: unset;
              }
              &::placeholder {
                color: #888;
                font-family: "Inter";
                font-size: 14px;
                font-weight: 400;
                line-height: 40px;
              }
            }
            img {
              cursor: pointer;
            }
          }
        }
        .login-btn {
          padding: 8px 16px;
          border-radius: 8px;
          background-color: #6f58ff;
          color: #f6f6f6;
          font-size: 14px;
          font-weight: 600;
          line-height: 28px;
          border: unset;
          text-align: center;
        }
      }
    }
    .login-card-footer {
      background-color: #f1f0fc;
      padding-top: 24px;
      padding-bottom: 24px;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      width: 500px;
      text-align: center;
      color: #192851;
      text-align: center;
      font-family: "Inter";
      font-size: 18px;
      font-weight: 500;
      span {
        color: #6f58ff;
        cursor: pointer;
        font-family: Inter;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }
}

.text-primary-color {
  color: #192851;
}

.privacy-content {
  color: #192851;
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 400;
  line-height: 28px; /* 28px */
}

.merchant-onboarding-content {
  color: #192851;
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 400;
  line-height: 28px; /* 28px */
}
.table-container {
  @media (max-width: 991px) {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .privacy-table {
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #d9d9d9;
    border-bottom: unset;
    border-radius: 12px;
    // overflow: hidden;
    table-layout: fixed;
    @media (max-width: 991px) {
      width: fit-content;
      display: block;
      table-layout: auto;
    }
    thead {
      th {
        background-color: #f1f0fc;
        font-family: "Open Sans";
        padding: 10px;
        text-align: center;
        color: #192851;
        font-size: 20px;
        border-right: 1px solid #d9d9d9;
        font-weight: 600;
        line-height: 28px;
        word-wrap: break-word;
        &:first-child {
          border-top-left-radius: 12px;
        }
        &:last-child {
          border-top-right-radius: 12px;
          border-right: unset;
        }
      }
    }

    td {
      padding: 24px 32px;
      text-align: left;
      border-right: 1px solid #d9d9d9;
      border-bottom: 1px solid #d9d9d9;
      word-wrap: break-word;
      color: #192851;
      font-size: 20px;
      font-weight: 400;
      line-height: 28px;
      font-family: "Open Sans";
      &:last-child {
        border-right: unset;
      }
    }
  }
}

.fw-600 {
  font-weight: 600 !important;
}
