.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  .modal-content {
    background-color: white;
    padding: 36px;
    border-radius: 5px;
    width: 35%;
    @media (max-width: 1023px) {
      width: 95%;
    }
    .modal-title {
      color: #192851;
      font-family: Raleway;
      font-size: 32px;
      font-weight: 600;
    }
    .modal-close {
      border: none;
      background: none;
      cursor: pointer;
      font-size: 20px;
    }
  }
  .form-control {
    padding-bottom: 16px;
    &::placeholder {
      color: #192851;
      font-family: "Open Sans";
      padding-bottom: 16px;
    }
  }
  .border-bottom-only {
    border: none;
    border-bottom: 2px solid #192851;
    border-radius: 0;
    &:focus {
      box-shadow: none;
      outline: none;
      border-bottom: 2px solid #192851;
    }
  }
  .submit-btn {
    color: #fff;
    text-align: center;
    font-family: "Open Sans";
    font-size: 20px;
    font-weight: 600;
    padding: 12px 0px;
    width: 100%;
    border-radius: 8px;
    border: unset;
    &.inactive {
      background-color: #A7A7A7;
      cursor: not-allowed;
    }
    &.active {
      background-color: #6F58FF;
      cursor: pointer;
    }
  }
}
